window.updateThemeIcon = (theme) => {
    document.getElementById("dark-mode-icon").classList = "hidden";
    document.getElementById("light-mode-icon").classList = "hidden";
    document.getElementById("system-mode-icon").classList = "hidden";
    document.getElementById(theme + "-mode-icon").classList.remove("hidden");
};

window.setTheme = (theme) => {
    if (theme === "dark") {
        document.documentElement.classList.add("dark");
        localStorage.setItem("theme", "dark");
    } else if (theme === "light") {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("theme", "light");
    } else if (theme === "system") {
        localStorage.setItem("theme", "system");
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }
    window.updateThemeIcon(theme);
};

document.addEventListener("DOMContentLoaded", function () {
    let savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
        window.setTheme(savedTheme);
    } else {
        savedTheme = "system";
        window.setTheme("system");
    }
});
